.speaker {
  text-align:center;
  margin-top:12px;
}

.speech {
  position: relative;
  width:330px;
  height:300px;
  text-align:center;
  margin-top:12px;
}

.align-left .speech img, .align-right .speech img {
  position: absolute;
  max-width: 310px;
  max-height: 270px;
  top: 50%;
  left: 50%;
  transform: translateX(-50%)translateY(-50%);
}

/* aligned left bubble */
.align-left .speaker {
  float:left;
  margin-left:10px;  
}

.align-left .speech {
  float:left;
  margin-left:10px;
  background-image: url('/public/assets/bubble-left.png');
}

.align-left .speech img {
  padding-left: 30px;
}

/* aligned right bubble */
.align-right .speaker {
  float:right;
  margin-right:10px;  
}

.align-right .speech {
  float:right;
  margin-right:10px;
  background-image: url('/public/assets/bubble-right.png');
}

.align-right .speech img {
  padding-right: 30px;
}

.prompt-image {
  position: relative;
  float: right;
  width:330px;
  text-align:center;
  margin-top:12px;
  margin-right:10px;
  cursor: pointer;
}

.prompt-image input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 97px;
  opacity: 0;
  cursor: pointer;
}

.prompt-image input img {
  cursor: pointer;
}

.story-button {
  margin-left: 150px;
  margin-top: 70px;
  width: 120px;
  cursor: pointer;
}

.next-image {
  margin-left: 285px;
  margin-top: 10px;
  z-index: 1000;
  position: relative;
}

.up-button, .down-button {
  position: absolute;
  width: 90px!important;
  height: 90px!important;
  background-color: #2893FC;
  border-radius: 16px;
  margin-left: 120px;
  cursor: pointer;
}

.up-button img, .down-button img {
  padding-left: 0px!important;
}

.up-button {
  top: 60px;
}


.down-button {
  top: 170px;
}