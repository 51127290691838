.bubble-scroll-container {
  position: fixed;
  min-width: 560px;
  top: 0;
  bottom: 70px;
  left: 0;
  right: 0;
}

.bottom-link-bar {
  text-align: center;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
}

.bottom-link-bar img {
  height: 70px;
}
