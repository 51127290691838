.bubble-container {
  min-width: 560px;
  max-width: 600px;
  margin: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* overflow-y: auto; */
  overflow: visible;
}


.grid-content {
  flex: 0 0 auto;
  margin-bottom: 1rem;
}
