body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide-logo .bottom-link-bar {
  display:none !important;
}

.hide-logo .bubble-scroll-container {
  bottom: 0 !important;
  overflow-y: auto;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: aliceblue;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #ffffff;
}